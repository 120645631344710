const Home = {
  text: "Home",
  link: "/home",
  icon: "icon-home",
  roles: ["ADMINISTRATOR"],
};

const Users = {
  text: "Users",
  link: "/users",
  icon: "icon-user",
  roles: ["ADMINISTRATOR"],
};

const Organizations = {
  text: "Organizations",
  link: "/organizations",
  icon: "fas fa-project-diagram",
  roles: ["ADMINISTRATOR"],
};

const Channels = {
  text: "Signer Channels",
  link: "/channels",
  icon: "fa icon-grid",
  roles: ["ADMINISTRATOR"],
};

const headingMain = {
  text: "Main Navigation",
  heading: true,
  roles: ["ADMINISTRATOR"],
};

const AchBatches = {
  text: "ACH Batches",
  link: "/ach-batches",
  icon: "fas fa-donate",
  roles: ["ADMINISTRATOR"],
};

const IntegrationEvents = {
  text: "Integration Events",
  link: "/integrationevents",
  icon: "fas fa-cogs",
  roles: ["ADMINISTRATOR"],
};

const Stellar = {
  text: "Stellar",
  link: "/stellar",
  iconUrl: "./../../../assets/img/stellar.png",
  roles: ["ADMINISTRATOR"],
};

export const menu = [
  headingMain,
  Home,
  Users,
  Organizations,
  Channels,
  AchBatches,
  IntegrationEvents,
  Stellar,
];
