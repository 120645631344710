import { SettingsService } from '../settings/settings.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { User } from '../models/user';
import { Observable, Subject } from 'rxjs';
import { AuthenticationResultDTO } from '../../dtos/authenticationResultDTO';
import { UserActivateDTO } from 'src/app/dtos/userActivateDTO';
import { ChangePasswordResultDto } from '../../dtos/changePasswordResultDto';
import { ResetPasswordResultDto } from '../../dtos/resetPasswordResultDto';


@Injectable()
export class AuthenticationService {

    private _userLoggedIn = new Subject<User>();
    public userLoggedIn = this._userLoggedIn.asObservable();

    private _UserUpdated = new Subject<User>();
    public onUserUpdated = this._UserUpdated.asObservable();

    constructor(
        private http: HttpClient,
        private settings: SettingsService) { }

    login(username: string, password: string): any {
        const url = `${environment.apiUrl}/authentication/login`;
        return this.http.post<AuthenticationResultDTO>(url, { email: username, password })
            .pipe(map(result => {
                // login successful if there's a jwt token in the response
                if (result && result.authenticated) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes

                    const user: User = plainToClass(User, { ...result.user, token: result.token });

                    localStorage.setItem('currentUser', JSON.stringify(user));

                    this._userLoggedIn.next(user);
                }

                return result;
            }));
    }

    activate(token: string, password: string): Observable<any> {
        const url = `${environment.apiUrl}/authentication/activate`;

        const dto: UserActivateDTO = {
            token,
            password
        };
        return this.http.put<any>(url, dto);
    }

    get isAuthenticated(): any {
        return localStorage.getItem('currentUser') != null;
    }

    logout(): any {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    currentUser(): User {
        try {
            const transformed = plainToClass(User, JSON.parse(localStorage.getItem('currentUser')));

            if (transformed) {
                if (Array.isArray(transformed)) {
                    return transformed[0];
                }
                return transformed;
            }

            return null;
        } catch (error) { return null; }
    }

    recover(email: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/authentication/recover`, { email });
    }

    resetPassword(token: string, newPassword: string): Observable<any> {
        return this.http.post<ResetPasswordResultDto>(`${environment.apiUrl}/authentication/reset-password`, { token, newPassword });
    }

    changePassword(oldPassword: string, newPassword: string): Observable<ChangePasswordResultDto> {
        return this.http.post<ChangePasswordResultDto>(`${environment.apiUrl}/authentication/change-password`, { oldPassword, newPassword });
    }

    changeExpiredPassword(token: string, oldPassword: string, newPassword: string): Observable<any> {
        return this.http.post<ChangePasswordResultDto>(`${environment.apiUrl}/authentication/change-expired-password`, { token, oldPassword, newPassword });
    }

    setCurrentUser(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this._UserUpdated.next(user);
    }

    clearStorage(): any {
        localStorage.removeItem('currentUser');
    }

}

