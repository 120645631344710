import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// import { ButtonsModule } from 'ngx-bootstrap/buttons';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
// import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FlotDirective } from './directives/flot/flot.directive';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { ColorsService } from './colors/colors.service';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { SortService } from './tables/sort.service';
import { SortableColumnComponent } from './tables/sortable-column.component';
import { SortableTableDirective } from './tables/sortable-table.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PageHeaderComponent } from './components/page-header/page-header.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // AlertModule.forRoot(),
        // ButtonsModule.forRoot(),
        // CarouselModule.forRoot(),
        // CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        // ModalModule.forRoot(),
        PaginationModule.forRoot(),
        // ProgressbarModule.forRoot(),
        // RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        // TooltipModule.forRoot(),
        // PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        NgxIntlTelInputModule
    ],
    providers: [
        ColorsService,
        SortService
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        ScrollableDirective,
        SortableColumnComponent,
        SortableTableDirective,
        PageHeaderComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // AlertModule,
        // ButtonsModule,
        // CarouselModule,
        // CollapseModule,
        DatepickerModule,
        BsDatepickerModule,
        BsDropdownModule,
        // ModalModule,
        PaginationModule,
        // ProgressbarModule,
        TabsModule,
        TimepickerModule,
        // TooltipModule,
        // PopoverModule,
        TypeaheadModule,
        SparklineDirective,
        ScrollableDirective,
        SortableColumnComponent,
        SortableTableDirective,
        FlotDirective,
        NgxIntlTelInputModule,
        PageHeaderComponent
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
