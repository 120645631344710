import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "./../../../environments/environment";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    if (!environment.development) {
      Sentry.init({
        dsn: "https://8c20b428ebfd4a7692de9784963d8b37@o390927.ingest.sentry.io/5915909",
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: [
              "localhost",
              "https://admintest.copernicuspay.cloud",
            ],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],

        tracesSampleRate: 1.0,
        maxValueLength: 1000,
        environment:
          (environment.production
            ? "production"
            : environment.staging
            ? "testing"
            : "development") +
          "-" +
          environment.version,
      });
    }
  }
  handleError(error) {
    if (!environment.development) {
      Sentry.captureException(error.originalError || error);
    }
    if (environment.development) {
      throw error;
    }
  }
}
