<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo">
                <h2 class="text-uppercase font-weight-bold">Cpay</h2>
            </div>
            <div class="brand-logo-collapsed">
                <img src="/assets/img/logo.png" alt="image">
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
    </ul>
    <!-- END Left navbar-->
    <ul class="navbar-nav flex-row">
        <li class="nav-item">
            <a class="nav-link" [routerLink]="'/users/profile'" title="Profile">
                <em class="icon-user"></em>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="/auth/login" title="Logout">
                <em class="icon-logout"></em>
            </a>
        </li>
    </ul>
</nav>
<!-- END Top Navbar-->
