import { Routes } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { LayoutComponent } from "../layout/layout.component";

export const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
      {
        path: "organizations",
        loadChildren: () =>
          import("./organizations/organizations.module").then(
            (m) => m.OrganizationsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
      {
        path: "ach-batches",
        loadChildren: () =>
          import("./ach-batches/ach-batches.module").then(
            (m) => m.AchBatchesModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
      {
        path: "channels",
        loadChildren: () =>
          import("./signer-channels/signer-channels.module").then(
            (m) => m.SignerChannelsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
      {
        path: "integrationevents",
        loadChildren: () =>
          import("./integration-events/integration-events.module").then(
            (m) => m.IntegrationEventsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
      {
        path: "stellar",
        loadChildren: () =>
          import("./stellar/stellar.module").then((m) => m.StellarModule),
        canActivate: [AuthGuard],
        data: { roles: ["ADMINISTRATOR"] },
      },
    ],
  },

  {
    path: "auth",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },

  {
    path: "error500",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  // Not found
  { path: "**", redirectTo: "home" },
];
