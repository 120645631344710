import { AuthGuard } from './core/guards/auth.guard';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoaderInterceptor } from './core/loading/loader.interceptor';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { ToastrModule } from 'ngx-toastr';
import { LoaderService } from './core/loading/loader.service';
import { ErrorHandler } from '@angular/core';
import { BaseService } from './core/services/base.service';
import { SentryErrorHandler } from './core/handler/sentry-error-handler';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        ToastrModule.forRoot({  timeOut: 3000, countDuplicates: true, preventDuplicates: true })
    ],
    providers: [
        AuthGuard,
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: ErrorHandler, useClass: SentryErrorHandler}

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
