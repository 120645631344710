import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NotificationsService } from '../notifications/notifications.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private notifications: NotificationsService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        if (currentUser) {
            const roles = route.data['roles'] as Array<string>;
            if (roles && roles.length > 0) {
                let isInRole = false;
                roles.forEach(role => (isInRole = isInRole || currentUser.isInRole(role)));
                if (!isInRole) {
                    this.notifications.error('Access Denied', 'You do not have permission');
                    this.router.navigate(['/home']);
                }
                return isInRole;
            }
            return true;
        }

        this.authenticationService.clearStorage();
        this.router.navigate(['/auth/login']);
        return false;
    }
}
