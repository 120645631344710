export class User {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    token: string;
    
    isInRole(role: string): boolean {
        return this.role && this.role.indexOf(role) !== -1;
    }
}
