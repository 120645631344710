import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { NotificationsService } from '../core/notifications/notifications.service';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private notifications: NotificationsService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.notifications.error('Authentication Required', 'Please sign in to access this page');
                this.authenticationService.logout();
                this.router.navigate(['/auth/login']);
                throw new Error(JSON.stringify(err));
            }

            if (err.status === 400) {
                let error: { [key: string]: string } = {};
                error = err.error.errors;
                if (error?.nonFieldError) {
                    this.notifications.error('Bad Request', error.nonFieldError);
                    //return;                
                }
            }

            if (err.status === 403) {
                this.notifications.error('Access Denied', 'You do not have permission');
                this.router.navigate(['/auth/login']);
                throw new Error(JSON.stringify(err));
            }

            if (err.status === 404) {
                this.notifications.error('Not found', err.status);
                //this.router.navigate(['/home']);
                throw new Error(JSON.stringify(err));
            }

            if (err.status === 500) {
                const params = {
                    error: err.error
                };
                this.router.navigate(['/error500'], {});
                throw new Error(JSON.stringify(err));
            }
            if (err.status === 0) {
                this.router.navigate(['/error500'], {});
                throw new Error(JSON.stringify(err));
            }

            if (err.error instanceof ProgressEvent) {
                this.notifications.error('Server error', 'The server is not responding');
                return;
            }

            let error = err.statusText;
            if (err.error ) {
                if (err.error.message) {
                    error = err.error.message;
                } else {
                    error = err.error;
                }
            }

            return throwError(error);
        }));
    }
}
